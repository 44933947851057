<form (ngSubmit)="onSubmit()" [formGroup]="formGroup">
  @if (isSummaryDisplayedSubject | async) {
    <govuk-error-summary [form]="formGroup"></govuk-error-summary>
  }

  <netz-page-heading [caption]="caption">{{ heading }}</netz-page-heading>

  <ng-content></ng-content>

  @if (!hideSubmit) {
    <button netzPendingButton govukButton type="submit">{{ submitText }}</button>
  }

  @if (showCancelLink) {
    <div>
      <a class="govuk-link" [routerLink]="cancelLinkPath">Cancel</a>
    </div>
  }
</form>
